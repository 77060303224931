html, body {
    margin: 0;
    padding: 0;
  }  
#projects {
    padding-top: 2%;   
  display: flex; 
  flex-direction: column; 
  text-align:center;
}
#projects img {
    padding:2%;
}