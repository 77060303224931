nav {
  position: fixed; 
  width: 100%;
}
nav ul {
    margin: 0;
    background-color:rgb(32,60,68);
    padding-bottom: .5%;
    padding-top: .5%;
    font-weight: bold;
    color:rgb(150, 172, 165);
    font-size: 150%;
  }

nav  li {
    display: inline;
    padding-left: 5%;
  }

nav  a{
    text-decoration: none;
    color:rgb(150, 172, 165);
    }
    
button {
      all: unset
    }