html, body {
    margin: 0;
    padding: 0;
  }  
#home {
  padding-top: 2%;  
  display: flex; 
  flex-direction: column; 
  text-align: center;
}
#home .zone {
    padding-top:30%;
    padding-bottom: 30%;
}
img {
    margin-right: auto;
    margin-left: auto;
}
#home p {
    padding-left: 10%;
    padding-right: 10%;
}
#epipelagic {
    background: linear-gradient(white,rgb(154, 204, 216));
}
#mesopelagic {
    background: linear-gradient(rgb(154, 204, 216),rgb(94, 143, 155));
}
#bathypelagic {
    background: linear-gradient(rgb(94, 143, 155),rgb(54, 97, 107));
}
#abyssopelagic {
    background:linear-gradient(rgb(54, 97, 107),rgb(28, 66, 75));
}
#hadalpelagic {
    background: linear-gradient(rgb(28, 66, 75),rgb(7, 32, 37));
    color:rgb(150, 172, 165);
}