@font-face {
    font-family: SH Pinscher;
    src: url(../media/SHPinscher-Regular.otf);
}

html, body {
    margin: 0;
    padding: 0;
  }  
#dialog {
    padding-top: 2%;   
  display: flex; 
  flex-direction: column; 
  text-align:center;
}
#dialogbox {
    background-color: grey;
    border: .5rem solid;
    border-color: black;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 10%;
    height: 15em;
    display: flex;
    flex-direction: row;

}
#textCSS {
    background-color: rgb(235, 226, 214);
    font-family: SH Pinscher;
    font-size: 200%;
    height:100%;
    width:20em;
    flex-grow: 4;
}
#characterbox {
    background-color: rgb(139, 110, 110);
    align-self: flex-end;
    height:100%;
    flex-grow:1; 
}

#characterbox img {
    max-width:100%;
    max-height:100%;
}